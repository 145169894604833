.statusCard {
  padding: 1rem 2rem;
  border-radius: $radius;

  margin-top: 2rem;
  &-status {
    font-size: 22px;
  }
  &-number {
    font-size: 76px;
    text-align: center;
    font-weight: 600;
    margin-top: 0.5rem;
  }
}

//notification section here

.notificationWrapper {
  padding: 1rem;

  .notificationList {
    padding: 0;

    &-day {
      color: rgb(104, 104, 104);
      text-align: right;
      font-weight: 300;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      cursor: pointer;
    }
    &-text {
      margin-top: 0.5rem;
      color: color(primary, base);
    }
    &-time {
      color: color(text-extra-light);
      font-size: 13px;
      font-weight: 300;
      text-align: left;
    }
  }
}

.dashboardWrapper {
  padding: 2rem;
  margin-left: 1rem;
  background: white;
}
.salesWrapper {
  padding: 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  background: white;
}
