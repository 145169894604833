.createadminWrapper {
  @include container;
}
.adminWrapper {
  @include container;
}

//transactinons

.transactionWrapper {
  @include container;

  .transFilter {
    display: flex;
    justify-content: flex-end;
    select {
      width: 20%;
    }
  }

  .transTable {
    margin-top: 1rem;
  }
}
.loginBack {
  background: rgb(245, 245, 245);
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.LoginWrapper {
  width: 30rem;
  overflow: hidden;
  background: white;
  padding: 1rem;
  border-radius: $radius;
  margin: 5rem auto;
  img {
    width: 12rem;
  }
}

.uploadedImage {
  height: 10rem;
  border: $border;
  padding: 0.5rem;
  img {
    height: 8rem;
    width: 8rem;
    object-fit: contain;
  }
}

.promoWrappper {
  padding-top: 2rem;
  .promoConatiner {
    @include container;

    &-inputs {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 34%;
        padding: 0 0.8rem;
        height: 2.5rem;
      }
    }

    &-list {
      padding: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        border-bottom: 1px solid rgb(218, 218, 218);
        padding: 0.5 0;
      }
    }
  }
}

//loader

.loadingHere {
  text-align: center;
  display: grid;
  place-items: center;
  padding-top: 2rem;
  height: 70vh;
}
.loader {
  width: 100px;
  height: 100px;
  position: relative;
}

.loader div {
  position: absolute;
  width: 15%;
  height: 15%;
  border-radius: 5px;
  animation: load 1s infinite ease-in-out;
  border: none;
}

.loader div:nth-of-type(1) {
  background-color: color(primary, base);
}

.loader div:nth-of-type(2) {
  background-color: color(secondary, base);
  animation-delay: 0.5s;
}

.loader div:nth-of-type(3) {
  background-color: #f8cb2e;
  animation-delay: 1s;
}
.loader div:nth-of-type(4) {
  background-color: #006e7f;
  animation-delay: 1.5s;
}

@keyframes load {
  0% {
    transform: translate(0%);
    border-radius: 50%;
  }

  25% {
    transform: translate(200%) rotate(45deg);
    border-radius: 0%;
  }

  50% {
    transform: translate(200%, 200%);
    border-radius: 50%;
  }

  75% {
    transform: translate(0, 200%) rotate(-45deg);
    border-radius: 0%;
  }
  100% {
    transform: translate(0%);
    border-radius: 50%;
  }
}
