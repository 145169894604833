.projectWrapper {
  display: flex;
  justify-content: space-between;
  .sidebarWrapper {
    width: 17%;
    height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: 0;

    &-brand {
      padding-top: 0.7rem;
      text-align: center;
      img {
        width: 10rem;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .contentWrapper {
    background: #f4fbfd;
    width: 83%;

    .navBar {
      background: color(white);
      padding-right: 2rem;
      box-shadow: $shadow;
      .navBox {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 3.8rem;
        &-items {
          padding: 0.8rem;
          font-weight: $font-medium;
          font-size: 1.2rem;

          .fa-user-circle {
            font-size: 1.5rem;
          }
          &-notification {
            position: relative;
            height: 2.5rem;
            width: 2.5rem;
            background: color(grey, three);
            border-radius: calc(2.5rem);
            @include flex-center;
            i {
              font-size: 1.4rem;
              color: color(primary, base);
            }
            .notificationCounter {
              position: absolute;
              top: -6px;
              right: -6px;
              background: red;
              height: 1.2rem;
              width: 1.2rem;
              border-radius: calc(1.2rem);
              font-size: 1rem;
              color: color(white);
              @include flex-center;
            }
          }
        }
        .adminName {
          font-size: $text-md;
        }
      }
    }
  }
}
