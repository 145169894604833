.ManCatWrapper {
  @include container;
  .MainCatCard {
    border: $border;
    margin-top: 2rem;
    padding: 0.5rem;
    &-image {
      // text-align: center;
      img {
        width: 100%;
        height: 7rem;
        object-fit: cover;
      }

      figcaption {
        margin-top: 0.5rem;
      }
    }

    &-text {
      height: 2rem;
      font-size: 14px;
    }
  }
}

.CatWrapper {
  @include container;

  .categories {
    &-tabs {
      margin-top: 2rem;
      margin-bottom: 1rem;
      .active {
        cursor: pointer;

        padding: 1rem;
        background: color(secondary, base);
        border-radius: $radius;
        color: white;
        width: 50%;
        font-size: 13px;
      }
      .passive {
        cursor: pointer;
        border-radius: $radius;
        background: rgb(243, 243, 243);
        padding: 1rem;
        width: 50%;
        font-size: 13px;
      }
    }
  }
}
.galleryWrapper {
  @include container;
  .center {
    text-align: center;
    border: 1px dashed color(primary, base);
    padding: 30px;
    margin: 15px 0;

    span {
      margin-top: 3px;
    }
  }

  .preview {
    height: 22rem;
    width: 100%;
    border: $border;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .imageList {
    margin-top: 1rem;
    height: 11rem;
    width: 11rem;
    background: rgb(184, 184, 184);
    border: $border;
    position: relative;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .editIcon {
      position: absolute;
      text-align: center;
      top: 7px;
      right: 10px;
      background: white;
      border: $border;
      padding: 0.2rem;
      height: 2rem;
      width: 2rem;
      border-radius: calc(2rem);

      cursor: pointer;
      color: rgb(49, 49, 49);
    }
    .deleteIcon {
      position: absolute;
      text-align: center;

      top: 7px;
      right: 5px;
      background: white;
      border: $border;
      padding: 0.2rem;
      height: 2rem;
      width: 2rem;
      border-radius: calc(2rem);

      cursor: pointer;
      color: rgb(49, 49, 49);

      i {
        color: color(danger, base);
      }
    }
  }
}

.preview {
  img {
    width: 100%;
  }
}

.descProduct {
  @include container;

  .Desclist {
    ul {
      padding: 0;
    }
    &-items {
      padding: 0.5rem;
      i {
        cursor: pointer;
      }
    }
  }

  .tagsHolder {
    border: $border;
    margin-top: 0.5rem;
    padding: 0.5rem;

    .tagPills {
      display: inline-flex;
      padding: 0.5rem;
      border: $border;

      border-radius: 6px;
    }
  }
}

.ProductsWrapper {
  @include container;

  .productFilter {
    display: flex;
    justify-content: flex-end;
    select {
      width: 20%;
    }
  }

  .tableHolder {
    .productList {
      display: flex;
      &-image {
        background: rgb(241, 241, 241);
        border: $border;
        margin: 0;
        img {
          height: 6rem;
          width: 6rem;
          object-fit: cover;
        }
      }
      &-name {
        margin-left: 0.5rem;
      }
    }
  }
}

//slider Carousel here

.sliderHolder {
  border: $border;
  background: white;
  padding: 1rem;
  height: 100%;

  margin-top: 1rem;

  &-image {
    height: 12rem;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .linkCarousel {
    margin-top: 1rem;
    text-decoration: underline;
    height: 3rem;
    overflow: hidden;
    margin-bottom: 1rem;
  }
}

.relatedDropdown {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 10px;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
}
